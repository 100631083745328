import { FinishNewsletterPopupProps } from '@husky-x/cc-finish-newsletter-popup/dist/types/models';

export const FinishProductDifferencesMock = {
  header: '<h2>AFLĂ CUM SE DIFERENȚIAZĂ PRODUSELE NOASTRE</h2>',
  borderedImage: true,
  navigationButton: {
    link: '/produse/',
    label: 'VEZI TOATE PRODUSELE',
  },
  useCheckmark: false,
  tables: [
    {
      title: 'FINISH',
      legend:
        '<ul> <li><sup>*</sup>elimină petele dificile chiar și la o încărcătură mare a mașinii de spălat vase</li> <li><sup>**</sup>comparat cu Finish Power bazat pe greutatea medie</li> <li><sup>***</sup>elimină petele de mâncare arsă</li> <li><sup>****</sup>ingredient: enzime</li> </ul>',
      rows: [
        {
          text: 'Curățare',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltea87c4c628b79c83/672b332d92fc2e334ae4afc0/clean.svg',
        },
        {
          text: 'Strălucire',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt93ba453d16253a88/672b332e44232cd2d703667d/glass-protection.svg',
        },
        {
          text: 'Îndepărtează resturile alimentare întărite de 48 ore',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blta2d07ab69b81d7e0/672b332e385a9f306c89a14c/dried-on.svg',
        },
        {
          text: 'Elimină orice urmă de grăsime',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt4e78d00db65bd109/672b332ef9f8c98e1f5cd550/tough-on-grease.svg',
        },
        {
          text: 'Renunță la pre-clătire',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltb7dea65dbe363da4/6745d0ce6b0aab7175f21058/icon-skip-pre-rinse.svg',
        },
        {
          text: 'Capsule fără ambalaje',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7a74010583deeaf3/6745d0ce6443359f0173e94f/icon-capsule-without-packing.svg',
        },
        {
          text: 'Dizolvare rapidă',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltce0e76ec7ad8c2ff/672b332ee26ed7180f4b15a8/fast-dissolving.svg',
        },
        {
          text: 'Greutate chimică mai mică',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt4f5999a44f766119/672b332ea9c28979b0f0865e/less-chemical-weight.svg',
        },
        {
          text: 'Protejează',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt93ba453d16253a88/672b332e44232cd2d703667d/glass-protection.svg',
        },
        {
          text: 'Extra prospețime',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt6bce8566a25bce5a/672b332eaf4968d9c2937157/freshness-boost.svg',
        },
        {
          text: 'Petele de mâncare arsă',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blte051fb1e44ab0838/672b332ef082aeb9a04ebbfb/burnt-on-stains.svg',
        },
        {
          text: 'Ingredientul potrivit la momentul potrivit<sup>****</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt80da07055c78fedf/672b332ea688993417c53602/right-ingredient-right-time-1.png',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt08b5189ea754374b/6745d0cee749702bd14a1781/ul-plus-group-ro.webp',
          productName: 'FINISH ULTIMATE PLUS',
          productLink: '/produse/capsule/capsulele-ultimate-plus/45-regular/',
          color: 'gold-gradient',
          values: [
            {
              value: 5,
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt412dc7fde5eb3f74/6745d0ce00b14cc60607ec15/ult-group-ro.webp',
          productName: 'FINISH ULTIMATE',
          productLink: '/produse/capsule/capsulele-ultimate/50-regular/',
          color: 'black-gradient',
          values: [
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 3,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltc945a2c585fafbb2/6745d0cec180342f396aa184/quantum-group-ro.webp',
          productName: 'FINISH QUANTUM',
          productLink: '/produse/capsule/capsulele-quantum/60-regular/',
          color: 'dark-blue-gradient',
          values: [
            {
              value: 3,
            },
            {
              value: 3,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt02b4cd4f07f8bdd4/6745d0cec458e95978aa470f/power-group-48-ro.webp',
          productName: 'FINISH POWER',
          productLink: '/produse/tablete/tabletele-power/48-regular/',
          color: 'blue-gradient',
          values: [
            {
              value: 2,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
    {
      title: 'FINISH 0%',
      legend: '<ul>\n<li><sup>*</sup>comparat cu Finish Power bazat pe greutatea medie</li>\n</ul>',
      rows: [
        {
          text: 'Certificat Eco',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blta27a535e4bb82354/67603476015cdfcf5ab90f30/svgviewer-png-output_(1).png',
        },
        {
          text: 'Curățare',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt548886b50983d27c/676010ef2440029b780829bd/clean.svg',
        },
        {
          text: 'Strălucire',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt48d4b60156c7218b/676010efbc1c7eef444fc4a7/shine.svg',
        },
        {
          text: 'Elimină orice urmă de grăsime',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt47e7b26c7ba2b0b4/676010ef6dece33fa985ea7d/tough-on-grease.svg',
        },
        {
          text: 'Renunță la pre-clătire',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt2d5709e63e6623f7/676016be7eeb6a7440346a61/skip-the-rinse.svg',
        },
        {
          text: 'Dizolvare rapidă',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt2f7a6152652d76e8/676010ef9dd7b854687c9886/fast-dissolving.svg',
        },
        {
          text: 'Greutate chimică mai mică*',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt6447c1f8daaebefe/676010efde48ee6659718ad2/less-chemical-weight.svg',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt07941af3bd69eb25/6761accb267b88c521bf5d30/power-0-group-ro.webp',
          productName: 'FINISH ULTIMATE 0%',
          productLink: '/produse/capsule/ultimate-0/45-0/',
          color: 'light-green-gradient',
          values: [
            {
              image:
                'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt73fff7ba23ebeab0/676041e13b2e632dc32e6733/svgviewer-png-output_(2).png',
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const FinishProductDifferencesMockBG = {
  header: '<h2>РАЗБЕРЕТЕ КАК СЕ ОТЛИЧАВАТ НАШИТЕ ПРОДУКТИ</h2>',
  borderedImage: true,
  useCheckmark: false,
  tables: [
    {
      title: 'FINISH',
      legend:
        '<ul> <li><sup>*</sup>премахва упорити петна дори при голямо натоварване на съдомиялна машина</li> <li><sup>**</sup>спрямо Finish Power и на база средно тегло</li> <li><sup>***</sup>Отстранява загоряла храна</li> <li><sup>****</sup>Състав: Ензим</li> </ul>',
      rows: [
        {
          text: 'Чисто',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltea87c4c628b79c83/672b332d92fc2e334ae4afc0/clean.svg',
        },
        {
          text: 'Блясък',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt93ba453d16253a88/672b332e44232cd2d703667d/glass-protection.svg',
        },
        {
          text: '48H Засъхнали петна<sup>*</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blta2d07ab69b81d7e0/672b332e385a9f306c89a14c/dried-on.svg',
        },
        {
          text: 'Упорит срещу мазнини',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt4e78d00db65bd109/672b332ef9f8c98e1f5cd550/tough-on-grease.svg',
        },
        {
          text: 'Бързо разтваряне',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltb7dea65dbe363da4/6745d0ce6b0aab7175f21058/icon-skip-pre-rinse.svg',
        },
        {
          text: 'По-малко химическо тегло<sup>**</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt4f5999a44f766119/672b332ea9c28979b0f0865e/less-chemical-weight.svg',
        },
        {
          text: 'Защита на стъклото',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt93ba453d16253a88/672b332e44232cd2d703667d/glass-protection.svg',
        },
        {
          text: 'Допълнителна свежест',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt6bce8566a25bce5a/672b332eaf4968d9c2937157/freshness-boost.svg',
        },
        {
          text: 'Загоряла храна<sup>***</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blte051fb1e44ab0838/672b332ef082aeb9a04ebbfb/burnt-on-stains.svg',
        },
        {
          text: 'Точната<sup>****</sup> съставка в Точното време',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt80da07055c78fedf/672b332ea688993417c53602/right-ingredient-right-time-1.png',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt08b5189ea754374b/6745d0cee749702bd14a1781/ul-plus-group-ro.webp',
          productName: 'FINISH ULTIMATE PLUS',
          productLink: '/produse/capsule/capsulele-ultimate-plus/45-regular/',
          color: 'gold-gradient',
          values: [
            {
              value: 5,
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt412dc7fde5eb3f74/6745d0ce00b14cc60607ec15/ult-group-ro.webp',
          productName: 'FINISH ULTIMATE',
          productLink: '/produse/capsule/capsulele-ultimate/50-regular/',
          color: 'black-gradient',
          values: [
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltc945a2c585fafbb2/6745d0cec180342f396aa184/quantum-group-ro.webp',
          productName: 'FINISH QUANTUM',
          productLink: '/produse/capsule/capsulele-quantum/60-regular/',
          color: 'dark-blue-gradient',
          values: [
            {
              value: 3,
            },
            {
              value: 2,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt02b4cd4f07f8bdd4/6745d0cec458e95978aa470f/power-group-48-ro.webp',
          productName: 'FINISH POWER',
          productLink: '/produse/tablete/tabletele-power/48-regular/',
          color: 'blue-gradient',
          values: [
            {
              value: 2,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
    {
      title: 'FINISH 0%',
      legend: '<ul>\n<li><sup>*</sup>в сравнение с Finish Power на база средно тегло</li>\n</ul>',
      rows: [
        {
          text: 'Еко сертификат',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blta27a535e4bb82354/67603476015cdfcf5ab90f30/svgviewer-png-output_(1).png',
        },
        {
          text: 'Чистота',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt548886b50983d27c/676010ef2440029b780829bd/clean.svg',
        },
        {
          text: 'Блясък',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt48d4b60156c7218b/676010efbc1c7eef444fc4a7/shine.svg',
        },
        {
          text: 'Отстранява всякакви следи от мазнини',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt47e7b26c7ba2b0b4/676010ef6dece33fa985ea7d/tough-on-grease.svg',
        },
        {
          text: 'Без предварително изплакване',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt2d5709e63e6623f7/676016be7eeb6a7440346a61/skip-the-rinse.svg',
        },
        {
          text: 'Бързо разтваряне',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt2f7a6152652d76e8/676010ef9dd7b854687c9886/fast-dissolving.svg',
        },
        {
          text: 'По-ниско химическо тегло*',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt6447c1f8daaebefe/676010efde48ee6659718ad2/less-chemical-weight.svg',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt07941af3bd69eb25/6761accb267b88c521bf5d30/power-0-group-ro.webp',
          productName: 'FINISH ULTIMATE 0%',
          productLink: '/produse/capsule/ultimate-0/45-0/',
          color: 'light-green-gradient',
          values: [
            {
              image:
                'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt73fff7ba23ebeab0/676041e13b2e632dc32e6733/svgviewer-png-output_(2).png',
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const FinishProductCardsMock = {
  cards: [
    {
      productName: 'FINISH ULTIMATE',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd37b7bf697699c8c/6745d0cd7279214a4903a21b/ult-50.webp',
      cardStyle: 'dark-blue',
      button: {
        link: '/produse/capsule/capsulele-ultimate/',
        text: 'CAPSULELE ULTIMATE',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt14c2a732b85ffe95/672b2b6adef94577005dc577/tough-on-grease.webp',
          name: 'ELIMINĂ ORICE URMĂ DE GRĂSIME',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd91d514e360acde8/672b2b6aa714b7238e595eb2/dried-on-stains.webp',
          name: 'ÎNDEPĂRTEAZĂ RESTURILE ALIMENTARE ÎNTĂRITE DE <strong>48</strong>ORE<sup>**</sup>',
        },
      ],
      mobileOrder: 2,
    },
    {
      productName: 'FINISH <br> ULTIMATE PLUS',
      primary: true,
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt35e61c257cbd1741/6745d0cea3cfe781c09a4ec8/ult-plus-45.webp',
      cardStyle: 'black',
      button: {
        link: '/produse/capsule/capsulele-ultimate-plus/',
        text: 'CAPSULELE ULTIMATE PLUS',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt116ceb8c905face0/672b2b6a07e8b65425b5cb3d/extra-cleaning-power.webp',
          name: '<strong>3X</strong> MAI MULTĂ PUTERE DE CURĂȚARE<sup>*</sup>',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd2797c2b7eca1490/672b2b6ad58fe80ac644cb9d/tough-on-grease-gold.webp',
          name: 'ELIMINĂ ORICE URMĂ DE GRĂSIME',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt56b7c2f9ec89726f/672b2b6a570b83949bb5c91b/burnt-on-stains.webp',
          name: 'PETELE DE MÂNCARE ARSĂ',
        },
      ],
      mobileOrder: 1,
    },
    {
      productName: 'FINISH QUANTUM',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt09abdd3605fc26ba/6745d0cebcfd5b3805621f04/quntum-60.webp',
      cardStyle: 'light-blue',
      button: {
        link: '/produse/capsule/capsulele-quantum/',
        text: 'CAPSULELE QUANTUM',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt14c2a732b85ffe95/672b2b6adef94577005dc577/tough-on-grease.webp',
          name: 'ELIMINĂ ORICE URMĂ DE GRĂSIME',
        },
      ],
      mobileOrder: 3,
    },
  ],
};

export const FinishProductCardsMockBG = {
  cards: [
    {
      productName: 'FINISH ULTIMATE',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd37b7bf697699c8c/6745d0cd7279214a4903a21b/ult-50.webp',
      cardStyle: 'dark-blue',
      button: {
        link: '/produse/capsule/capsulele-ultimate/',
        text: 'ОПИТАЙТЕ ULTIMATE',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt14c2a732b85ffe95/672b2b6adef94577005dc577/tough-on-grease.webp',
          name: 'УПОРИТ СРЕЩУ МАЗНИНИ',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd91d514e360acde8/672b2b6aa714b7238e595eb2/dried-on-stains.webp',
          name: '<strong>48</strong> ЧАСА ЗАСЪХНАЛИ ПЕТА<sup>**</sup>',
        },
      ],
      mobileOrder: 2,
    },
    {
      productName: 'FINISH <br> ULTIMATE PLUS',
      primary: true,
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt35e61c257cbd1741/6745d0cea3cfe781c09a4ec8/ult-plus-45.webp',
      cardStyle: 'black',
      button: {
        link: '/produse/capsule/capsulele-ultimate-plus/',
        text: 'ОПИТАЙТЕ НАШИЯ НАЙ-ДОБЪР ULTIMATE PLUS',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt116ceb8c905face0/672b2b6a07e8b65425b5cb3d/extra-cleaning-power.webp',
          name: '<strong>3X</strong> ПОЧИСТВАЩА СИЛА<sup>*</sup>',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd2797c2b7eca1490/672b2b6ad58fe80ac644cb9d/tough-on-grease-gold.webp',
          name: 'УПОРИТ СРЕЩУ МАЗНИНИ',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt56b7c2f9ec89726f/672b2b6a570b83949bb5c91b/burnt-on-stains.webp',
          name: 'ЗАГОРЯЛА ХРАНА',
        },
      ],
      mobileOrder: 1,
    },
    {
      productName: 'FINISH QUANTUM',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt09abdd3605fc26ba/6745d0cebcfd5b3805621f04/quntum-60.webp',
      cardStyle: 'light-blue',
      button: {
        link: '/produse/capsule/capsulele-quantum/',
        text: 'ОПИТАЙТЕ QUANTUM',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt14c2a732b85ffe95/672b2b6adef94577005dc577/tough-on-grease.webp',
          name: 'УПОРИТ СРЕЩУ МАЗНИНИ',
        },
      ],
      mobileOrder: 3,
    },
  ],
};

export const FinishNewsletterPopupMock: FinishNewsletterPopupProps = {
  headline: 'NICHTS MEHR VERPASSEN',
  image: {
    src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7512496341f5a468/66f53c689441d6ee01fe1a7d/newsletter_popup_image.png',
    mobileSrc:
      'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt63c4941b86e10813/66f53c833097ebb254ddd0d8/newsletter_popup_image_mobile.png',
    alt: 'finish',
  },
  thankYou: {
    headline: 'VIELEN DANK FÜR IHRE ANMELDUNG FÜR DEN FINISH NEWSLETTER!',
    description: '',
    closeButton: {
      label: 'schließen',
      redirectUrl: '/',
    },
  },
  form: {
    id: '7f4311c4-00e3-48f9-a9cb-13b20ef2b28f',
    url: 'https://phx-promoplatform-api-pl-prod.promoplatform.rbcloud.io/api/v1/external/forms',
    submitErrorMessage: 'Bitte füllen Sie die erforderlichen Felder aus.',
  },
};
